import './crew.css';
import CrewItem from './crew-item';
import crewTitle from '../images/crew-title-desktop.png';
import mediaTitleMobile from  '../images/crew-title-mobile.png';
import photoDogi from "../images/Dogi-profile-v2.jpg";
import photoKesha from "../images/Kesha-profile-v2.jpg";
import photoViktor from "../images/Viktor-profile-v3.jpg";
import photoDogiMobile from "../images/Dogi-profile-mobile.jpg";
import photoKeshaMobile from "../images/Kesha-profile-mobile.jpg";
import photoViktorMobile from "../images/Viktor-profile-mobile.jpg";

function CrewSection(crew) {

return (
	<div id="crew-section">
	  	<a className="anchor" id="band-members"></a>
	  	
	  	<div className="row container-fluid bg-black bg-breaks">
			    
		    <div className="col-md-1 themed-grid-col"></div>
	        
	        <div className="col-md-10 themed-grid-col">
					<div className="text-center" id="band-members-title">
						<picture>
					  		<source srcSet={mediaTitleMobile} media="(max-width: 699px)"/>
					  		<img id="crew-title"
								src={crewTitle} 
								className="img-fluid" alt="Crew Logo"
								loading="lazy"
								height="144px" width="443px" style={{ maxWidth: '100%', height: 'auto'}}
								data-font-name="adnp1" data-font-url="https://fontmeme.com/graffiti-creator/"/>
						</picture>
					</div>
					
					<div id="band-members-content" className="row row-cols-1 row-cols-md-3 g-4">
						{/**
						<CrewItem
							picture={profilePictureMatti}
							name="Matti S.Aikio"
							shortBio="Bassbounding troll from the dark forests of Finland. Howls like a wolf and growls like a wolverine in heat."
						/>
						*/}
						<CrewItem
							picture={photoViktor}
							pictureMobile={photoViktorMobile}
							name="Viktor Kosan"
							shortBio="Red bass guitar, long hair, even longer fingers and a big smile on his face. This guy plays every show like it’s his last. Always ready for action, young and hot, straight from Berlin."
						/>

						<CrewItem
							picture={photoDogi}
							pictureMobile={photoDogiMobile}
							name="Mr. Dogi T."
							shortBio="Like a good old whiskey, from the age of 15 Mr.T started to rock hard in bars and clubs of Berlin. Relentless heavy kick mixed with his oriental roots brings a special touch to heavy metal coctail."
						/>

						<CrewItem
							picture={photoKesha}
							pictureMobile={photoKeshaMobile}
							name="Kesha Bender"
							shortBio="Born in Siberia and raised under the burning sun of Ukraine, Kesha pocesses the famous rebellious spirit of Cossacks, which results in roaring guitar riffs and dramatic melodies."
						/>
					</div>
					<br/>			
		        </div>
		        
			    <div className="col-md-1 themed-grid-col"></div>
		  	
		  	</div>
	  	
	</div>
  );
}

export default CrewSection;