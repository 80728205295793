import './App.css';
import logo from './images/font-v5-ukraine.png';
import logoMobile from './images/font-v5-ukraine.webp';

function Header() {
  return (

  	<div name="header">
		<nav className="navbar fixed-header navbar-expand-lg navbar-dark background">
		  	<div className="container-fluid">
				    <a className="navbar-brand" href="#" aria-label="band logo">
								<picture>
					  			<source srcSet={logoMobile} media="(max-width: 699px)"/>
									<img src={logo} height="45" alt="band logo" loading="lazy"/> 
								</picture>
							</a>
				    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
				      <span className="navbar-toggler-icon"></span>
				    </button>
				    <div className="collapse navbar-collapse" id="navbarNav">
				      <ul className="navbar-nav">
				        <li className="nav-item">
				          <a className="nav-link" href="#band-media">Media</a>
				        </li>
				        <li className="nav-item">
				          <a className="nav-link" href="#band-members">Crew</a>
				        </li>
				        <li className="nav-item">
				          <a className="nav-link" href="#footer">Contact</a>
				        </li>
				      </ul>
				    </div>
			  </div>
		</nav>
  	</div>
  );
}

export default Header;
