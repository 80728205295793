import emblemMobile from '../images/wallpaper_mobile.jpg';
import emblemMain from '../images/wallpaper-laptop_optimized.jpg';
import emblemDesktop from '../images/ZMEY_emblem2_white.png';

function PosterSection() {
  return (
	<div id="poster-section" className="bg-black container-fluid">
	 	
	 	<picture>
	  		<source srcSet={emblemMobile} media="(max-width: 699px)" sizes="100vw"/>
	  		<img src={emblemMain} 
	  			className="img-fluid zmey-logo" alt="Zmey Logo"
	  			height="1066" width="2558"
  			/>
		</picture>
	 	
	  	<div name="band-intro-text" className="container-fluid bg-white-transperent">  		
		  	<div className="row">
			    <div className="col-md-1 themed-grid-col"></div>
		        <div className="col-md-10 themed-grid-col">
					<br/>
					<h4 className="text-dark text-center font-major"><strong>Modern Hard & Heavy from Berlin</strong></h4>
					<h5 className="text-dark text-center font-regular"><strong>Punchy riffs, catchy melodies, raw vocals and a great live performance!</strong></h5>
					<br/>
		        </div>
		        
			    <div className="col-md-1 themed-grid-col"></div>
		  	</div>
	  	</div>

	</div>
  );
}

export default PosterSection;
