function CrewItem(props) {
  return (
	<div className="col">
	<div className="card">
	    
			<picture>
  				<source srcSet={props.pictureMobile} media="(max-width: 699px)"/>
			    
			    <img className="card-img-top" src={props.picture} 
			    	
			    	style={{ maxWidth: '100%', height: 'auto'}} 
			    	alt={props.name}
		    	/>
    	</picture>

	    <div className="card-body bg-dark">
	      <h5 className="card-title font-bold text-center text-white">{props.name}</h5>
	      <p className="card-text text-white">{props.shortBio}</p>
	    </div>
	</div>
	</div>
);}
/** background-color:#470202 */
export default CrewItem;
