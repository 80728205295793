function MediaItem(props) {
  return (
			<div className="col">
				<div className="card">						
				  <div className="ratio ratio-16x9 border">
						<iframe src={props.link} 
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							title={`youtube video: ${props.title}`}
						>
						</iframe>
				  </div>
				  <div className="card-body bg-light">
				    {/**<h5 className="card-title font-bold">{props.title}</h5>**/}

				    <p className="card-text font-regular">{props.description}</p>
				  </div>
				</div> 
		 	</div>
  );
}

export default MediaItem;
