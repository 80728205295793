import './App.css';
import './footer.css';

function Footer() {
  return (
	<div>
	  	<div name="footer" id="footer" className=".container-fluid bg-black text-light text-center">

	  	<div className="row">
			    <div className="col-md-1 themed-grid-col"></div>
		        <div className="col-md-10 themed-grid-col text-center">
								<a href="https://www.facebook.com/zmeyrockband/" className="fa fa-facebook" aria-label="facebook link"></a>
								<a href="https://www.youtube.com/@zmey.band.berlin" className="fa fa-youtube" aria-label="youtube link"></a>
								<a href="https://www.instagram.com/zmey_band/" className="fa fa-instagram" aria-label="instagram link"></a>
		        </div>
		        
			    <div className="col-md-1 themed-grid-col"></div>
		  	</div>


			<span className="border-bottom border-danger font-bold">mail@zmey.band</span>
			<p/>
			{/**©Zmey - All rights reserved */}
  		</div>
	</div>
);
}

export default Footer;
