import './App.css';
import Header from './header';
import PosterSection from './homepage/poster-section';
import GigsSection from './homepage/gigs-section';
import MediaSection from './homepage/media-section';
import CrewSection from './homepage/crew-section';
import AnouncementSection from './homepage/anouncement-section.js'
import Footer from './footer';

function Homepage() {
  return (
	<div>
		<Header />
		<PosterSection />
		{/** <AnouncementSection /> **/}
		<MediaSection />
		<CrewSection />
		{/**
		<GigsSection />
		 */}
		<Footer />
	</div>
  );
}

export default Homepage;
